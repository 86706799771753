<template>
    <div class="x-contain">

        <!-- 手机 - 密码登入 -->
        <el-form ref="form" :model="form" label-position="top" class="x-right-phone">
            
            <!-- 标题 -->
            <el-image src="/img/system/logoA.png" style="width: 200px; height: 200px; margin: auto;"></el-image>
            <el-link type="primary" :underline="false" style="margin-bottom: 20px;"><h1>安全运营平台-密码登入</h1></el-link>

            <!-- 手机号码 -->
            <el-form-item :rules="[{ required: true, message: '手机号不能为空' }]" :error="formError.phone?.toString()" prop="phone">
                <el-input prefix-icon="el-icon-mobile-phone" v-model="form.phone" placeholder="手机号 151****2296" error  />
            </el-form-item>

            <!-- 密码 -->
            <el-form-item :rules="[{ required: true, message: '请输入密码' }]" :error="formError.password?.toString()" prop="password">
                <el-input prefix-icon="el-icon-lock" v-model="form.password" placeholder="密码 A-Z/a-z/0-9/特殊字符" error  />
            </el-form-item>

            <el-button type="primary" @click="SubmitLoginPhonePwd()">登入</el-button>
        </el-form>

    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                phone: null,
                password: null,
            },
            
            formError: {
                phone: null, 
                password: null,
            }
        }
    },

    mounted() {
        this.$http.get('/9api/sanctum/csrf-cookie').then(response => {
            console.log(response)
        })
    },

    methods: {

        /**
         * 密码登入 - 提交表单
         */
        async SubmitLoginPhonePwd() {

            this.formError = {}

            // 提交表单
            await this.$http.post('/9api/login',  this.form).then((rps) => {
                
                // 验证失败
                if (rps.data.status == 'error') {
                    
                    this.formError = rps.data.error
                }
                
                // 验证成功
                if (rps.data.status == 'success') {
                    // 记录token
                    sessionStorage.setItem('token', rps.data.success)

                    // 即将跳转的页面
                    let path = sessionStorage.getItem('pathTo') ?? '/'
                    let link = path != '/auth/login' ? path : '/'

                    // 移动端校验
                    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                        alert("移动设备")
                        let url = new URL('https://aqyy_m.waner.cn' + link)
                        url.searchParams.set('token', rps.data.success)
                        window.location = url.toString()
                        alert(url.toString())
                        return;
                    } else {
                        this.$router.push(link);
                    }
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.x-contain {
    width: 100%;
    height: 100%;
    background: url("/img/login/login-dialog-left.png") no-repeat center center;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .x-right-phone {
        width: 400px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        
        .el-button {
            width: 100%;
        }
    }
}
</style>
